@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');
html,body,#root,.app,.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans Pro',sans-serif;
}
.app{
  display: flex;
  position: relative;
}

::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb{
  background: #888;
}

::-webkit-scrollbar-thumb:hover{
  background: #555;
}
 .jodit-status-bar__item-right{
  display: none;

}

.tagify{
  width: 100%;
  background: rgba(255, 255, 255, .09);
  margin-top: 15px;
  border: 0px solid rgba(255, 255, 255, 0);
  border-bottom: 1px solid white;
  padding: 6px 3px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}