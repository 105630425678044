/* Define your animations here */
.fade-enter {
    opacity: 0;
    transform: translateY(200px);
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 1000ms;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 500ms, transform 1000ms;
  }
  